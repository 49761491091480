import { amountFormatter } from 'helpers'
import { PaymentSummaryType } from '../types'

type ClientTransactionDataType = {
    allEnrollment: number
    balance: number
    last_voucher_date?: string
}

export const buildPaymentSummaryProps = (
    { allEnrollment, balance }: ClientTransactionDataType,
    lastVoucherDate: string,
    country: string
): PaymentSummaryType => ({
    allEnrollment: amountFormatter(allEnrollment, country),
    balance: amountFormatter(balance, country),
    paymentLeft: amountFormatter(allEnrollment - balance, country),
    percentage: parseFloat(((balance / allEnrollment) * 100).toString()),
    lastVoucherDate
})
