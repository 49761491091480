/* eslint-disable no-unused-vars */
import React from 'react'
import { useTranslation } from 'react-i18next'
import { TransactionPayloadType } from 'storeTypes'
import { PaymentItem } from './PaymentItem'

interface PaymentRowProps {
    transactionList: TransactionPayloadType[]
    country: string
    dateReceived: string
}

export const PaymentRow = ({
    transactionList,
    dateReceived,
    country
}: PaymentRowProps) => {
    const { t } = useTranslation()

    return (
        <div className="client-payment--history-section">
            <p className="payment--date">
                {t('fullDateMonthYear', {
                    date: new Date(dateReceived || '')
                })}
            </p>
            {[...transactionList].reverse().map((transaction, idx) => (
                <PaymentItem
                    transaction={transaction}
                    country={country}
                    key={idx}
                />
            ))}
        </div>
    )
}
