import axios from 'axios'
import { constants, getConnectUser } from 'helpers'

const connectApi = () => {
    const axiosInstance = axios.create({
        baseURL: process.env.REACT_APP_API_BASE_URL
    })

    // eslint-disable-next-line space-before-function-paren
    axiosInstance.interceptors.request.use(function (config) {
        const path = window.location.pathname
        const { token } = getConnectUser()
        // logout users that don't have tokens
        if (path !== '/login' && !token) {
            localStorage.removeItem(constants.CONNECT_USER)
            localStorage.removeItem('connect_theme')
            return (window.location.href = '/login')
        }

        config.headers['MyAgro-Application-Name'] = 'connect-v2'
        config.headers.Authorization = `Bearer ${token}`
        const isTrainingModeOn =
            localStorage.getItem('connect_training_mode') === 'true'
        config.baseURL = isTrainingModeOn
            ? process.env.REACT_APP_API_TRAINING_URL
            : process.env.REACT_APP_API_BASE_URL
        return config
    })

    axiosInstance.interceptors.response.use(
        function (response) {
            return response
        },
        function (error) {
            // logout users with expired auth token
            if (error?.response) {
                if (
                    window.location.pathname !== '/login' &&
                    error.response?.status === 403
                ) {
                    localStorage.removeItem(constants.CONNECT_USER)
                    return (window.location.href = '/login')
                }

                return error.response
            } else if (error?.request) {
                return error.request
            }
            return error?.message
        }
    )

    return axiosInstance
}

export default connectApi()
