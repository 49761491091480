import React, { useState, useEffect, useCallback } from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'

import {
    amountFormatter,
    buildCardContentFromDict,
    constants,
    getInteractionsFromClientStatus,
    interactionOutcomeStyles,
    routes
} from '../../helpers'
import { camelCase } from 'lodash'
import { Button } from '../button/Button'
import {
    arrowUpIcon,
    arrowDownIcon,
    chevronDownIcon,
    phoneIcon,
    StarIcon,
    HutIcon,
    FlagIcon
} from '../../assets'
import './card.css'
import { t } from 'i18next'
import { useBindDispatch, useGetStorage } from '../../hooks'
import { findPermission } from '../../helpers/findPermission'
import { Spinner } from '../spinner/Spinner'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { ClientInteractions, InteractionOutcomes } from 'types'
import { findInteraction } from 'helpers/interactions/interactionHelper'

export const CardClientDetails = ({
    children,
    clientCode,
    clientPhone,
    otherClientData,
    modifyHandler,
    disableEdit = false,
    collapseMore = false,
    buttonProps,
    preCertified = false,
    preCertifiedButtonLoader,
    clientPage = constants.CLIENT_TYPES.CURRENT_CLIENT,
    isClientDuplicate = false,
    clientStatus,
    interactionHandler,
    balance,
    ...props
}) => {
    const { interactions } = useSelector(({ interaction }) => interaction)
    const { recentCardPayment } = useSelector((state) => state.cardPayment)

    const [displayMore, setDisplayMore] = useState(false)
    const [displayInteractions, setDisplayInteractions] = useState(false)
    const [clientInteractionStatus, setClientInteractionStatus] = useState()
    const [clientInteraction, setClientInteraction] = useState({})

    const navigate = useNavigate()
    const { pathname } = useLocation()

    const {
        UPDATE_CLIENT,
        CAN_VIEW_INTERACTION_LIST,
        USER_PERMISSIONS,
        CLIENT_DETAIL_LOCAL_STORE,
        CONNECT_USER,
        CLIENT_FOR_ENROLLMENT_STORE,
        CLIENT_VISIT_INTERACTION
    } = constants
    const userPermissions = useGetStorage(USER_PERMISSIONS)
    const localClientData = JSON.parse(
        localStorage.getItem(CLIENT_DETAIL_LOCAL_STORE)
    )
    const canViewInteractions = findPermission(
        userPermissions,
        CAN_VIEW_INTERACTION_LIST
    )
    const showModifyButton = !isClientDuplicate && !canViewInteractions
    const showInteractionsButton = canViewInteractions && !isClientDuplicate
    const storedClientVisitInteraction = useGetStorage(CLIENT_VISIT_INTERACTION)
    const veData = useGetStorage(CONNECT_USER)

    const { paymentNotificationHandler } = useBindDispatch()

    useEffect(() => {
        if (
            interactions?.length > 0 &&
            storedClientVisitInteraction?.client_code !== clientCode
        ) {
            const foundInteraction = findInteraction(interactions, clientCode)

            if (foundInteraction) {
                setClientInteraction(foundInteraction)
                localStorage.setItem(
                    constants.CLIENT_VISIT_INTERACTION,
                    JSON.stringify(foundInteraction)
                )
            }
        } else if (storedClientVisitInteraction?.client_code === clientCode) {
            setClientInteraction({ ...storedClientVisitInteraction })
        }
    }, [interactions?.length, clientCode])

    useEffect(() => {
        if (collapseMore) {
            setDisplayMore(true)
        }
    }, [])

    useEffect(() => {
        if (clientInteractionStatus?.className !== 'duplicate') {
            if (isClientDuplicate) {
                setClientInteractionStatus(
                    interactionOutcomeStyles[InteractionOutcomes.DUPLICATE]
                )
            } else {
                setClientInteractionStatus(
                    interactionOutcomeStyles[
                        clientInteraction?.outcome || 'default'
                    ]
                )
            }
        }
    }, [isClientDuplicate, clientInteraction])

    useEffect(() => {
        paymentNotificationHandler(veData.username)
    }, [recentCardPayment?.length])

    const formatPhone = amountFormatter(clientPhone)
    const { villageId } = useParams()

    const modifyClickHandler = () => {
        // include form type and village info in client data
        const { veVillages } = veData
        // get village name
        const { name: villageName } = veVillages.find(
            ({ sf_id: sfId }) => sfId === localClientData.village_id
        ) || { name: '' }
        localClientData.form_type = 'modification'
        localClientData.village_name = villageName
        localClientData.village = localClientData.village_id
        localStorage.setItem(
            CLIENT_FOR_ENROLLMENT_STORE,
            JSON.stringify(localClientData)
        )

        localStorage.setItem(
            CLIENT_FOR_ENROLLMENT_STORE,
            JSON.stringify({
                ...localClientData,
                modification: constants.CLIENT_MODIFICATION_TYPES.DETAILS
            })
        )
        return navigate(`/${routes.enroll}/${villageId}?from=${pathname}`)
    }
    const displayEditBtn =
        modifyHandler &&
        findPermission(userPermissions, UPDATE_CLIENT) &&
        !isClientDuplicate

    const otherClientDataRender = buildCardContentFromDict(
        otherClientData,
        ['card-client-detail--bottom', displayMore ? 'showMore' : 'hideMore']
            .join(' ')
            .trim(),
        modifyClickHandler,
        displayEditBtn
    )
    const closeColor = '#16a34a'

    const toggleMoreHandler = () => {
        setDisplayMore(!displayMore)
    }

    const handleInteractionBtnClick = () => {
        setDisplayInteractions(!displayInteractions)
    }

    const filterOutDuplicateFlagInteractionWhen = useCallback(
        (conditionIsTrue, inInteractions) =>
            conditionIsTrue
                ? inInteractions?.filter(
                      (interaction) =>
                          interaction.name !== ClientInteractions.DUPLICATE_FLAG
                  )
                : inInteractions,
        [clientInteraction, balance]
    )

    const isPaymentPending = recentCardPayment?.length > 0

    const buildInteractionList = () => {
        const interactions = getInteractionsFromClientStatus(clientStatus)

        const isBalanceEligible = balance && balance !== 0
        const condition = isBalanceEligible || isPaymentPending
        const filteredInteractions = filterOutDuplicateFlagInteractionWhen(
            condition,
            interactions
        )

        return filteredInteractions.map(({ name, outcome, icon }, idx) => {
            const buttonID = `interaction-${name.replace(/\s+/g, '-')}-btn`
            return (
                <Button
                    key={idx}
                    className={
                        buttonID +
                        (clientPage === constants.CLIENT_TYPES.PREVIOUS_CLIENT
                            ? ' interaction-btn-small'
                            : '')
                    }
                    style="primary"
                    iconPos="left"
                    icon={icon}
                    onClick={() => {
                        interactionHandler({ name, outcome })
                        setDisplayInteractions(false)
                    }}
                    data-testid={buttonID}
                    id={buttonID}
                >
                    {t('clientDetails.' + camelCase(name))}
                </Button>
            )
        })
    }

    const profileIcon = isClientDuplicate ? (
        <FlagIcon width="50" height="50" color="red" />
    ) : preCertified ? (
        <StarIcon />
    ) : (
        <HutIcon color={clientInteractionStatus?.color} />
    )

    return (
        <div {...props}>
            <div className="card-client-detail--top">
                <div className="name-abr-id">
                    <div className="card-client-detail--icons">
                        {profileIcon}
                    </div>
                    <div className="">
                        <div className="card-client-detail--name-icon">
                            <h2 className="card-client-detail--name">
                                {children}{' '}
                            </h2>
                        </div>
                        <p className="card-client-detail--id">
                            ID {clientCode}
                        </p>
                    </div>
                </div>
                <div
                    className={`card-client-detail--${
                        canViewInteractions
                            ? 'contact-interactions'
                            : 'contact-no-interactions'
                    }`}
                >
                    <Button
                        className={'card-client-detail--button'}
                        style={clientInteractionStatus?.btnStyle}
                        icon={phoneIcon({
                            color: clientInteractionStatus?.color
                        })}
                        iconPos="right"
                        id="client-details-phone-btn"
                        data-testid="phone-button"
                    >
                        <a
                            href={`tel:${clientPhone}`}
                            className={[
                                'phone-number-text',
                                `phone-number-text--${
                                    isClientDuplicate
                                        ? 'duplicate'
                                        : clientInteractionStatus?.className
                                }`
                            ].join(' ')}
                        >
                            {formatPhone}
                        </a>
                    </Button>
                    {showModifyButton && (
                        <Button
                            className={'card-client-detail--button'}
                            style="primary"
                            onClick={modifyHandler}
                            disable={preCertifiedButtonLoader || disableEdit}
                            data-testid="call-to-action-btn"
                            id="client-details-modify-btn"
                        >
                            {preCertifiedButtonLoader ? (
                                <Spinner size="40" />
                            ) : (
                                buttonProps?.title
                            )}
                        </Button>
                    )}
                </div>
                {showInteractionsButton ? (
                    <>
                        <Button
                            className="card-client-detail--button-interactions"
                            style="primary"
                            iconPos="right"
                            icon={
                                displayInteractions
                                    ? arrowUpIcon
                                    : arrowDownIcon
                            }
                            onClick={handleInteractionBtnClick}
                            data-testid="interactions-btn"
                            id="interactions-btn"
                        >
                            {t('clientDetails.interactions')}
                        </Button>
                        <div
                            className={[
                                'interactions-container',
                                displayInteractions ? 'show' : 'hide'
                            ].join('--')}
                        >
                            {buildInteractionList()}
                        </div>
                    </>
                ) : (
                    <></>
                )}
            </div>
            {otherClientDataRender}
            <div
                className="view-more"
                onClick={toggleMoreHandler}
                data-testid="view-more-button"
            >
                <p>
                    {displayMore ? (
                        <span className="close-display">
                            {t('clientDetails.seeLess')}
                        </span>
                    ) : (
                        t('clientDetails.seeMore')
                    )}
                </p>
                <span className={displayMore ? 'icon-down' : 'icon-up'}>
                    {chevronDownIcon(displayMore && { color: closeColor })}
                </span>
            </div>
        </div>
    )
}

CardClientDetails.propTypes = {
    children: PropTypes.string,
    clientCode: PropTypes.string,
    clientPhone: PropTypes.string,
    balance: PropTypes.number,
    otherClientData: PropTypes.object,
    modifyHandler: PropTypes.func,
    disableEdit: PropTypes.bool,
    buttonProps: PropTypes.object,
    collapseMore: PropTypes.bool,
    preCertified: PropTypes.bool,
    preCertifiedButtonLoader: PropTypes.bool,
    clientPage: PropTypes.oneOf([
        constants.CLIENT_TYPES.CURRENT_CLIENT,
        constants.CLIENT_TYPES.PREVIOUS_CLIENT
    ]),
    isClientDuplicate: PropTypes.bool,
    clientStatus: PropTypes.string,
    interactionHandler: PropTypes.func
}

CardClientDetails.defaultProps = {
    buttonProps: { text: t('edit') }
}
