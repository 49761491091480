import { ActionTypes } from 'actions'
import { TransactionsStateType, TransactionsActionType } from 'storeTypes'

const initState: TransactionsStateType = {
    transactions: [],
    loading: true,
    error: ''
}

export const transactionsReducer = (
    state: TransactionsStateType = initState,
    action: TransactionsActionType
): TransactionsStateType => {
    switch (action.type) {
        case ActionTypes.FETCHING_TRANSACTIONS:
            return {
                ...state,
                loading: true
            }
        case ActionTypes.FETCH_TRANSACTIONS_SUCCESS:
            return {
                ...state,
                loading: false,
                transactions: action.payload as []
            }
        case ActionTypes.FETCH_TRANSACTIONS_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload as string
            }
        case ActionTypes.FETCH_TRANSACTIONS_COMPLETE:
            return {
                ...state,
                loading: false,
                transactions: action.payload as []
            }
        default:
            return state
    }
}
