import connectApi from '../api/connectApi'
import {
    constants,
    sendMessageToWorker,
    sendMessageToWorkerWithResponse,
    internetConnect,
    canUseRxDb
} from '../helpers'
import {
    hasLocalDataExpired,
    updateLocalDataExpirationTime
} from '../datastores/LocalDataExpiration'
import { Dispatch } from 'redux'
import {
    TransactionsActionType,
    TransactionsInterface,
    TransactionPayloadType
} from '../storeTypes'
import { ActionTypes } from 'actions'
import { CLIENT_TYPES } from '../datastores/constants'
import { refreshVillageClientListAction } from './villageClientAction'
import { mergeClientsWithTransactions } from '../datastores/Merger'
import * as Database from 'database/Database'

export const fetchTransactionsAction =
    ({ villageId }: TransactionsInterface) =>
    async (dispatch: Dispatch<TransactionsActionType>) => {
        const { TRANSACTIONS } = constants.endpoints
        dispatch({ type: ActionTypes.FETCHING_TRANSACTIONS })

        if (
            !hasLocalDataExpired(
                constants.TRANSACTIONS_EXPIRATION_NAME,
                villageId
            )
        ) {
            const { transactions } = await sendMessageToWorkerWithResponse({
                'get transactions': {
                    villageId
                }
            })

            await updateClientsWithTransactions(
                villageId,
                transactions,
                dispatch
            )

            return dispatch({
                type: ActionTypes.FETCH_TRANSACTIONS_COMPLETE,
                payload: transactions
            })
        }

        if (!internetConnect()) {
            return dispatch({
                type: ActionTypes.FETCH_TRANSACTIONS_FAILURE,
                payload: 'Pas de connexion internet'
            })
        }

        try {
            const { data: transactions } = await connectApi.get(TRANSACTIONS, {
                params: {
                    village_ids: villageId
                }
            })

            updateLocalDataExpirationTime(
                constants.TRANSACTIONS_EXPIRATION_NAME,
                villageId,
                constants.TRANSACTIONS_EXPIRATION_DURATION
            )

            transactions.forEach((transaction: TransactionPayloadType) => {
                // TODO: Remove this after we have updated all the smss.client references to smss.client_code
                transaction.client = transaction.client_code
            })

            sendMessageToWorker({
                'store transactions': {
                    transactions
                }
            })

            await updateClientsWithTransactions(
                villageId,
                transactions,
                dispatch
            )

            dispatch({
                type: ActionTypes.FETCH_TRANSACTIONS_SUCCESS,
                payload: transactions
            })
        } catch (error: unknown) {
            dispatch({
                type: ActionTypes.FETCH_TRANSACTIONS_FAILURE,
                payload: 'Un problème est survenu'
            })
        }
    }

async function updateClientsWithTransactions(
    villageId: string,
    transactions: TransactionPayloadType[],
    dispatch: Dispatch<TransactionsActionType>
) {
    if (canUseRxDb()) {
        const database = await Database.get()
        database?.clients
            .find()
            .exec()
            .then((clients) => {
                if (clients.length > 0) {
                    refreshVillageClientListAction({
                        villageId,
                        clients: mergeClientsWithTransactions(
                            clients.map((item) => item.toMutableJSON()),
                            transactions
                        )
                    })(dispatch)
                }
            })
    } else {
        const { storedData: clients } = await sendMessageToWorkerWithResponse({
            'get client data': {
                villageIds: [villageId],
                clientType: CLIENT_TYPES.AllClient,
                filter: 'village'
            }
        })

        if (clients?.length > 0) {
            const clientsWithTransactions = mergeClientsWithTransactions(
                clients,
                transactions
            )

            refreshVillageClientListAction({
                villageId,
                clients: clientsWithTransactions
            })(dispatch)
        }
    }
}
