type ClientTransaction = {
    clientCode: string
    balance: number
    transactionList: { date_received: string }[]
}

type ClientInfo = {
    clientCode: string
    balance: number
    smss: []
    lastVoucherDate?: string
}

export const getClientTransactionDetails = (
    { clientCode, balance, smss, lastVoucherDate }: ClientInfo,
    clientsTransactions: ClientTransaction[]
) => {
    const singleClientTransactions = clientsTransactions?.find(
        (transaction) => transaction.clientCode === clientCode
    ) as ClientTransaction

    const clientBalance =
        balance >= (singleClientTransactions?.balance || 0)
            ? balance
            : singleClientTransactions?.balance
    const clientSmss =
        smss?.length >= singleClientTransactions?.transactionList?.length
            ? smss
            : singleClientTransactions?.transactionList
    const lastPaymentDate =
        lastVoucherDate ||
        (singleClientTransactions?.transactionList?.length > 0 &&
            singleClientTransactions?.transactionList[0].date_received)

    return { clientBalance, clientSmss, lastPaymentDate }
}
