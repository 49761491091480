import { constants, formatDateToFullDate, sortHandler } from 'helpers'
import { groupBy } from 'lodash'
import {
    CardPaymentPayloadType,
    TransactionKind,
    TransactionPayloadType
} from 'storeTypes'

const createNonSyncedPayments = (payment: CardPaymentPayloadType) => ({
    ...payment,
    date_received: formatDateToFullDate(new Date(payment.date_received) || ''),
    voucher_code: payment.transaction_id,
    type: TransactionKind.PENDING,
    amount: 'hideAmount'
})

const filterNonSyncedPayments = (
    clientPayments: CardPaymentPayloadType[],
    transactions: string[]
) =>
    clientPayments.map((payment) =>
        !transactions?.includes(payment.transaction_id)
            ? createNonSyncedPayments(payment)
            : ({} as CardPaymentPayloadType)
    )

const consolidateTransactions = (
    nonSyncedPayments: CardPaymentPayloadType[],
    clientTransactions: TransactionPayloadType[]
): (CardPaymentPayloadType | TransactionPayloadType)[] => [
    ...nonSyncedPayments,
    ...clientTransactions
]

export const sortAndGroupTransactions = (
    transactions: (CardPaymentPayloadType | TransactionPayloadType)[]
) => {
    const sortedTransactions = sortHandler(
        transactions,
        'date_received',
        constants.SORT_TYPE.DATE,
        constants.SORT_DIRECTION.DESCENDING
    )
    return groupBy(sortedTransactions, 'date_received')
}

export const processPaymentsUpdate = (
    clientPayments: CardPaymentPayloadType[],
    clientTransactions: TransactionPayloadType[]
) => {
    const transactionsIds = clientTransactions?.map(
        ({ voucher_code: voucherCode }) => voucherCode || ''
    )
    const nonSyncedPayments = filterNonSyncedPayments(
        clientPayments,
        transactionsIds
    )
    const consolidatedTransactions = consolidateTransactions(
        nonSyncedPayments,
        clientTransactions
    )

    return sortAndGroupTransactions(consolidatedTransactions)
}
