import connectApi from '../api/connectApi'
import {
    constants,
    sendMessageToWorkerWithResponse,
    sendMessageToWorker,
    internetConnect,
    canUseRxDb,
    fetchAllClientList
} from '../helpers'
import { Dispatch } from 'redux'
import {
    CardInterface,
    CardActionTypes,
    CardEnum,
    SearchClientListInterface,
    CardPaymentEnum,
    CardPaymentActionType,
    CardPaymentInterface,
    CardPaymentResultInterface,
    StoredDataType
} from '../storeTypes'
import { ErrorResponse } from '../types'

export const cardInventoryAction =
    ({ veCode }: CardInterface) =>
    // eslint-disable-next-line space-before-function-paren
    async (dispatch: Dispatch<CardActionTypes>) => {
        const { VE_CARD_INVENTORY } = constants.endpoints
        dispatch({ type: CardEnum.FETCHING_CARD_INVENTORY_DATA })
        try {
            const { data } = await connectApi.get(VE_CARD_INVENTORY, {
                params: {
                    code: veCode
                }
            })
            dispatch({
                type: CardEnum.FETCH_CARD_INVENTORY,
                payload: data
            })
        } catch (error: unknown) {
            if (error instanceof ErrorResponse) {
                dispatch({
                    type: CardEnum.FAIL_REQUEST,
                    payload: error.response.data.detail
                })
            } else {
                dispatch({
                    type: CardEnum.FAIL_REQUEST,
                    payload: 'something went wrong'
                })
            }
        }
    }

export const searchClientList =
    ({ clientCode }: SearchClientListInterface) =>
    async (dispatch: Dispatch<CardActionTypes>) => {
        const allClientData: { storedData: StoredDataType[] } = {
            storedData: []
        }

        if (canUseRxDb()) {
            const clientList = await fetchAllClientList()
            if (clientList) {
                allClientData.storedData = clientList.map((item) => item._data)
            }
        } else {
            const currentClient = await sendMessageToWorkerWithResponse({
                'get client data': {
                    clientType: constants.CLIENT_TYPES.CURRENT_CLIENT,
                    filter: 'village'
                }
            })
            const potentialCLient = await sendMessageToWorkerWithResponse({
                'get client data': {
                    clientType: constants.CLIENT_TYPES.PREVIOUS_CLIENT,
                    filter: 'village'
                }
            })

            allClientData.storedData = [
                ...currentClient.storedData,
                ...potentialCLient.storedData
            ]
        }

        dispatch({
            type: CardEnum.FETCH_CARD_CLIENT_LIST,
            payload: {
                allClientData,
                storedData: allClientData.storedData
            },
            clientCode
        })
    }

export const cardPaymentAction =
    ({ paymentData }: CardPaymentInterface) =>
    async (dispatch: Dispatch<CardPaymentActionType>) => {
        const { VE_CARD_PAYMENT } = constants.endpoints
        dispatch({ type: CardPaymentEnum.SENDING_CARD_PAYMENT })
        let payment = { ...paymentData }
        if (!internetConnect()) {
            payment = {
                ...payment,
                status: 'offline',
                failed_msg:
                    'Your payment will be recorded at your next connection!',
                amount: ''
            }
        }
        sendMessageToWorker({
            'store payment notification': [payment]
        })
        try {
            connectApi
                .post(VE_CARD_PAYMENT, {
                    ...paymentData
                })
                .then(({ data }) => {
                    dispatch({
                        type: CardPaymentEnum.SENT_CARD_PAYMENT,
                        payload: data,
                        payment
                    })
                })
                .catch(({ response }) => {
                    dispatch({
                        type: CardPaymentEnum.FAILED_CARD_PAYMENT,
                        payload: response?.data
                    })
                })
        } catch (error: unknown) {
            if (error instanceof ErrorResponse) {
                dispatch({
                    type: CardPaymentEnum.FAILED_CARD_PAYMENT,
                    payload: error.response.data.detail
                })
            } else {
                dispatch({
                    type: CardPaymentEnum.FAILED_CARD_PAYMENT,
                    payload: 'something went wrong'
                })
            }
        }
    }

export const cardPaymentResultAction =
    (params: CardPaymentResultInterface) =>
    async (dispatch: Dispatch<CardPaymentActionType>) => {
        const { VE_CARD_PAYMENT } = constants.endpoints
        dispatch({ type: CardPaymentEnum.FETCHING_CARD_PAYMENT_RESULT })
        try {
            const { data } = await connectApi.get(VE_CARD_PAYMENT, {
                params
            })
            sendMessageToWorker({
                'store payment notification': data
            })
            dispatch({
                type: CardPaymentEnum.FETCHED_CARD_PAYMENT_RESULT,
                payload: data
            })
        } catch (error: unknown) {
            if (error instanceof ErrorResponse) {
                dispatch({
                    type: CardPaymentEnum.FAILED_CARD_PAYMENT_RESULT,
                    payload: error.response.data.detail
                })
            } else {
                dispatch({
                    type: CardPaymentEnum.FAILED_CARD_PAYMENT_RESULT,
                    payload: 'something went wrong'
                })
            }
        }
    }

export const clearCardPaymentAction = () => ({
    type: CardPaymentEnum.CLEAR_CARD_PAYMENT
})

export const clearCardPaymentResultAction = () => ({
    type: CardPaymentEnum.CLEAR_CARD_PAYMENT_RESULT
})

export const clearSearchClientResultAction = () => ({
    type: CardEnum.CLEAR_CARD_CLIENT_LIST
})
