import React from 'react'
import { TransactionPayloadType } from 'storeTypes'
import { PaymentRow } from './PaymentRow'
import { isSupportedTransaction } from 'helpers'

type RenderPaymentHistoryProps = {
    transactions: [string, TransactionPayloadType[]][]
    country: string
}

export const RenderPaymentHistory: React.FC<RenderPaymentHistoryProps> = ({
    transactions,
    country
}) => (
    <div>
        {transactions.map(([dateReceived, transactionList], idx) => {
            const filteredTransactionList = transactionList.filter(
                isSupportedTransaction
            )
            return (
                filteredTransactionList.length > 0 && (
                    <PaymentRow
                        key={idx}
                        country={country}
                        transactionList={filteredTransactionList}
                        dateReceived={dateReceived as string}
                    />
                )
            )
        })}
    </div>
)
