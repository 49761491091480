import { useEffect, useState } from 'react'
import {
    processPaymentsUpdate,
    sortAndGroupTransactions
} from '../helpers/processClientTransactionHelpers'
import { CardPaymentPayloadType, TransactionPayloadType } from 'storeTypes'

export const useUpdateProcessedPayments = (
    clientPayments: CardPaymentPayloadType[],
    currentClientTransactions: TransactionPayloadType[]
) => {
    const [processedPayment, setProcessedPayment] = useState({})

    useEffect(() => {
        if (clientPayments?.length > 0) {
            setProcessedPayment(
                processPaymentsUpdate(clientPayments, currentClientTransactions)
            )
        } else {
            setProcessedPayment(
                sortAndGroupTransactions(currentClientTransactions)
            )
        }
    }, [clientPayments, currentClientTransactions])

    return processedPayment
}
