import { useState, useEffect } from 'react'
import { TransactionPayloadType } from 'storeTypes'
import { useFormatDateOnTransactions } from './useFormatDateOnTransactions'
import { useBindDispatch } from 'hooks'
import { groupBy } from 'lodash'
import { getVoucherMatchingClientCode } from 'datastores/Merger'

type ClientTransactionData = {
    balance: number
    transactionList: TransactionPayloadType[]
}

export const extractClientTransactions = (
    transactions: TransactionPayloadType[],
    clientCode: string
) =>
    transactions?.filter(
        (transaction) => transaction.client_code === clientCode
    )

export const calculateBalance = (transactions: TransactionPayloadType[] = []) =>
    transactions.reduce(
        (balance, { amount }) => (amount ? balance + amount : balance),
        0
    )

export const useBuildClientTransactionData = (
    transactions: TransactionPayloadType[],
    clientCode: string,
    villageId: string
) => {
    const { transactionsHandler } = useBindDispatch()
    const [clientTransactionData, setClientTransactionData] =
        useState<ClientTransactionData>({
            balance: 0,
            transactionList: []
        })

    useEffect(() => {
        if (transactions?.length > 0) {
            const transactionsByPaymentType = groupBy(transactions, 'type')
            const transactionsByClient = groupBy(transactions, 'client_code')
            getVoucherMatchingClientCode(
                transactionsByPaymentType,
                transactions
            )

            setClientTransactionData({
                balance: calculateBalance(transactionsByClient[clientCode]),
                transactionList: transactionsByClient[clientCode]
            })
        } else {
            transactionsHandler({ villageId })
        }
    }, [transactions, clientCode])

    return {
        balance: clientTransactionData.balance,
        transactionList:
            useFormatDateOnTransactions({
                transactionList: clientTransactionData.transactionList
            }) || [],
        lastPayment:
            clientTransactionData.transactionList?.length > 0 &&
            clientTransactionData?.transactionList[0].date_received
    }
}
