import React, { useEffect, useRef, useState } from 'react'
import { CSSTransition } from 'react-transition-group'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { Container, TopBar } from 'components'
import { constants, getConnectUser } from 'helpers'
import { useBindDispatch, useGetClient, useNetworkConnection } from 'hooks'
import { useSelector } from 'react-redux'
import { useBuildClientTransactionData } from './hooks/useBuildClientTransactionData'
import { buildPaymentSummaryProps } from './helpers/buildPaymentSummaryProps'
import { PaymentLoadingSection } from './components/PaymentLoadingSection'
import { PaymentHistoryContent } from './components/PaymentHistoryContent'
import { useUpdateProcessedPayments } from './hooks/useProcessClientTransactions'
import { useGetClientPayments } from './hooks/useGetClientPayments'

export const ClientPaymentHistory = () => {
    const { isOnline } = useNetworkConnection()
    const [animate, setAnimate] = useState(false)
    const { recentCardPayment } = useSelector((state) => state.cardPayment)
    const { transactions } = useSelector((state) => state.transactions)
    const { paymentData } = useSelector((state) => state.paymentNotification)
    const { villageId: urlVillageId, clientId: urlClientCode } = useParams()
    const [searchParams] = useSearchParams()
    const reload = searchParams.get('reload')
    const nodeRef = useRef(null)
    const { t } = useTranslation()
    const {
        navbarHandler,
        updateSingleClientBalAndSmsHandler,
        paymentNotificationHandler
    } = useBindDispatch()
    const { country, username } = getConnectUser()
    const navigate = useNavigate()
    const { client, loadingClient } = useGetClient(urlClientCode)
    const { balance, transactionList } = useBuildClientTransactionData(
        transactions,
        client?.client_code,
        urlVillageId
    )

    const clientPayments = useGetClientPayments(
        paymentData,
        recentCardPayment,
        client?.client_code
    )

    const processedPayments = useUpdateProcessedPayments(
        clientPayments,
        transactionList,
        client?.client_code
    )

    useEffect(() => {
        setAnimate(true)
        navbarHandler(constants.SHOW_NAVBAR)
        if (reload) {
            updateSingleClientBalAndSmsHandler({
                villageId: urlVillageId,
                clientId: urlClientCode
            })
        }
    }, [reload])

    useEffect(() => {
        paymentNotificationHandler(username)
    }, [recentCardPayment?.length, isOnline])

    const backNavigationHandler = () =>
        navigate(`/village/${urlVillageId}/client/${urlClientCode}`)

    const {
        fullname,
        all_enrollment: allEnrollment,
        last_voucher_date: lastVoucherDate
    } = client || {}

    const validatedLastVoucherDate = lastVoucherDate
        ? t('fullDateMonthYear', { date: new Date(lastVoucherDate) })
        : '-'

    return (
        <>
            <div className="header-bar" id="header-bar">
                <Container>
                    <TopBar back backNavigation={backNavigationHandler}>
                        <span className="client-payment-history--header">
                            {fullname}
                        </span>
                    </TopBar>
                </Container>
            </div>
            {loadingClient ? (
                <PaymentLoadingSection
                    loadingText={t('fetchingPaymentLoader')}
                />
            ) : (
                <CSSTransition
                    unmountOnExit
                    timeout={constants.ANIMATION_TIMEOUT}
                    in={animate}
                    classNames="generic"
                    appear
                    onEnter={() => setAnimate(true)}
                    nodeRef={nodeRef}
                >
                    <PaymentHistoryContent
                        paymentSummary={buildPaymentSummaryProps(
                            {
                                allEnrollment,
                                balance
                            },
                            validatedLastVoucherDate,
                            country
                        )}
                        transactions={Object.entries(processedPayments)}
                        country={country}
                        animate={animate}
                    />
                </CSSTransition>
            )}
        </>
    )
}
