import React from 'react'
import PropTypes from 'prop-types'
import { Links, ProgressBar } from '../../components'
import { useGetStorage } from '../../hooks'
import { useTranslation } from 'react-i18next'
import { constants } from '../../helpers'
import { findPermission } from '../../helpers/findPermission'

export const PaymentSummary = ({ paymentSummary, paymentHistoryLink }) => {
    const { t } = useTranslation()
    const userPermissions = useGetStorage(constants.USER_PERMISSIONS)
    const canViewPaymentHistory = findPermission(
        userPermissions,
        constants.VIEW_PAYMENTS
    )
    const {
        finisher_progress_high_threshold: finisherProgressHighThreshold,
        finisher_progress_medium_threshold: finisherProgressMediumThreshold
    } = useGetStorage('country_config')

    const { allEnrollment, balance, lastVoucherDate, percentage, paymentLeft } =
        paymentSummary
    return (
        <div className="village-client-details-padding village-payment--main">
            <div className="client-details--header display-flex space-btw">
                <p className="client-details--content">{t('payment')}</p>
                {paymentHistoryLink && canViewPaymentHistory && (
                    <Links
                        className="card-top-link"
                        to="payment-history"
                        id="view-history-link"
                    >
                        {t('viewHistory')}
                    </Links>
                )}
            </div>
            <div className="village-payment--progress">
                <ProgressBar
                    percentage={percentage}
                    ranges={{
                        high: finisherProgressHighThreshold,
                        low: finisherProgressMediumThreshold
                    }}
                />
                <div className="village-payment--progress-figures display-flex space-btw ai-fe">
                    <h2
                        className="total-payment--made"
                        data-testid="payment-balance"
                    >
                        {balance}
                    </h2>
                    <h2 className="total-payment--amount">
                        <span className="total-payment--of">{t('on')} </span>
                        {allEnrollment}
                    </h2>
                </div>
            </div>
            <div className="village-payment--amount-date">
                {paymentLeft?.charAt(0) !== '-' ? (
                    <div className="display-flex space-btw village-payment--rest-amount">
                        <p>{t('village.leftToPay')}</p>
                        <p data-testid="remaining-to-pay">{paymentLeft}</p>
                    </div>
                ) : (
                    <div className="display-flex space-btw village-payment--rest-amount">
                        <p>{t('village.remainingBalance')}</p>
                        <p data-testid="remaining-balance">
                            {paymentLeft.substring(1)}
                        </p>
                    </div>
                )}
                <div className="display-flex space-btw village-payment--rest-amount">
                    <p>{t('village.lastPayment')}</p>
                    <p>{lastVoucherDate}</p>
                </div>
            </div>
        </div>
    )
}

PaymentSummary.propTypes = {
    paymentSummary: PropTypes.object,
    paymentHistoryLink: PropTypes.bool
}
