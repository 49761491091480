import React from 'react'
import { Container, Row, Col, Card } from 'components'
import { PaymentSummary } from '../PaymentSummary'
import { RenderPaymentHistory } from '../RenderPaymentHistory'
import { TransactionPayloadType } from 'storeTypes'
import { PaymentSummaryType } from '../types'

type PaymentHistoryContentProps = {
    paymentSummary: PaymentSummaryType
    transactions: [string, TransactionPayloadType[]][]
    country: string
    animate: boolean
}

export const PaymentHistoryContent: React.FC<PaymentHistoryContentProps> = ({
    paymentSummary,
    transactions,
    country,
    animate
}) => (
    <div
        className="client-payment-parent page-wrapper"
        data-testid="payment-history-card"
    >
        <Container>
            <Row>
                <Col md={12}>
                    <Card
                        className={
                            animate
                                ? 'height-100 animate-show-1 animate-showed-1'
                                : 'height-100 animate-show-1'
                        }
                    >
                        <div className="village-client-details-padding village-payment--main">
                            <PaymentSummary paymentSummary={paymentSummary} />
                            <div className="client-payment--history-parent">
                                <RenderPaymentHistory
                                    transactions={transactions}
                                    country={country}
                                />
                            </div>
                        </div>
                    </Card>
                </Col>
            </Row>
        </Container>
    </div>
)
