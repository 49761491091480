import {
    CardEnum,
    CardActionTypes,
    CardPayloadType,
    CardSummary,
    CardPaymentEnum,
    CardPaymentActionType,
    CardPaymentPayloadType
} from '../storeTypes'

const initialState: CardPayloadType = {
    cardData: []
}
export const cardReducer = (
    state: CardPayloadType = initialState,
    action: CardActionTypes
): CardPayloadType => {
    switch (action.type) {
        case CardEnum.FETCH_CARD_LIST:
            return {
                cardData: action.payload,
                ...state
            }

        default:
            return state
    }
}

const cardInventoryInitialState: CardPayloadType = {
    cardInventoryData: [],
    cardDetailData: []
}

type ItemsType<T, K> = {
    cards_in_packet: T
    used_cards: T
    amount: K
}
const combineCardDetails = (data: ItemsType<string, string>[]) => {
    const items: ItemsType<number, string>[] = []
    for (const item of data) {
        const packet = items.find((pkt) => pkt.amount === item.amount)
        if (packet) {
            packet.cards_in_packet += parseFloat(item.cards_in_packet)
            packet.used_cards += parseFloat(item.used_cards)
        } else {
            items.push({
                cards_in_packet: parseFloat(item.cards_in_packet),
                used_cards: parseFloat(item.used_cards),
                amount: item.amount
            })
        }
    }
    return items
}

const cardSummary = (
    combinedCardDetails: ItemsType<number, string>[]
): CardSummary => {
    let totalCardSold = 0
    let totalCardReceived = 0
    let totalCardAmountSold = 0
    combinedCardDetails.forEach(
        ({ cards_in_packet: cardsInPacket, used_cards: usedCards, amount }) => {
            totalCardReceived += cardsInPacket
            totalCardSold += usedCards
            totalCardAmountSold += usedCards * parseInt(amount)
        }
    )

    return {
        totalCardReceived,
        totalCardSold,
        totalCardRemaining: totalCardReceived - totalCardSold,
        totalCardAmountSold
    }
}

export const cardInventoryReducer = (
    state: CardPayloadType = cardInventoryInitialState,
    action: CardActionTypes
): CardPayloadType => {
    switch (action.type) {
        case CardEnum.FETCHING_CARD_INVENTORY_DATA:
            return {
                loading: true,
                cardInventoryData: [],
                cardDetailData: [],
                error: ''
            }
        case CardEnum.FETCH_CARD_INVENTORY: {
            const combinedCardsData = combineCardDetails(action.payload)
            return {
                loading: false,
                cardInventoryData: action.payload,
                cardDetailData: combinedCardsData as [],
                cardSummary: cardSummary(combinedCardsData),
                error: ''
            }
        }
        case CardEnum.FAIL_REQUEST:
            return {
                loading: false,
                cardInventoryData: [],
                cardDetailData: [],
                error: action.payload
            }
        default:
            return state
    }
}

export const searchClientListIndexReducer = (
    state: CardPayloadType = { searchedClient: [] },
    action: CardActionTypes
): CardPayloadType => {
    switch (action.type) {
        case CardEnum.FETCH_CARD_CLIENT_LIST: {
            const { payload, clientCode } = action
            const filteredClientData = payload.storedData.filter((clientData) =>
                clientData.client_code.includes(clientCode)
            )
            return {
                searchedClient: filteredClientData as []
            }
        }
        case CardEnum.CLEAR_CARD_CLIENT_LIST:
            return {
                searchedClient: []
            }

        default:
            return state
    }
}

export const cardPaymentReducer = (
    state: CardPayloadType = { cardPaymentResponse: '', recentCardPayment: [] },
    action: CardPaymentActionType
): CardPayloadType => {
    switch (action.type) {
        case CardPaymentEnum.SENDING_CARD_PAYMENT:
            return {
                ...state,
                loading: true,
                error: ''
            }
        case CardPaymentEnum.SENT_CARD_PAYMENT:
            return {
                ...state,
                loading: false,
                cardPaymentResponse: action.payload,
                recentCardPayment: [
                    ...(state.recentCardPayment as CardPaymentPayloadType[]),
                    { ...action.payment } as CardPaymentPayloadType
                ],
                error: ''
            }
        case CardPaymentEnum.FAILED_CARD_PAYMENT:
            return {
                ...state,
                loading: false,
                cardPaymentResponse: '',
                error: action.payload
            }
        case CardPaymentEnum.CLEAR_CARD_PAYMENT:
            return {
                loading: false,
                cardPaymentResponse: '',
                error: ''
            }
        default:
            return state
    }
}

export const cardPaymentResultReducer = (
    state: CardPayloadType = { paymentResponse: [] },
    action: CardPaymentActionType
): CardPayloadType => {
    switch (action.type) {
        case CardPaymentEnum.FETCHING_CARD_PAYMENT_RESULT:
            return {
                ...state,
                loading: true,
                error: '',
                paymentResponse: []
            }
        case CardPaymentEnum.FETCHED_CARD_PAYMENT_RESULT:
            return {
                ...state,
                loading: false,
                paymentResponse: action.payload,
                error: ''
            }
        case CardPaymentEnum.FAILED_CARD_PAYMENT_RESULT:
            return {
                ...state,
                loading: false,
                paymentResponse: [],
                error: action.payload
            }
        case CardPaymentEnum.CLEAR_CARD_PAYMENT_RESULT:
            return {
                loading: false,
                paymentResponse: [],
                error: ''
            }
        default:
            return state
    }
}
