import { useMemo } from 'react'
import { TransactionPayloadType } from 'storeTypes' // Assuming this is the correct path for your types
import { formatDateToFullDate } from 'helpers' // Assuming this is where you format the date

type UseFormatDateOnTransactions = {
    transactionList: TransactionPayloadType[]
}

export const useFormatDateOnTransactions = ({
    transactionList
}: UseFormatDateOnTransactions): TransactionPayloadType[] => {
    return useMemo(
        () =>
            transactionList?.map((transaction) => ({
                ...transaction,
                date_received: formatDateToFullDate(
                    new Date(transaction.date_received || '')
                )
            })),
        [transactionList]
    )
}
