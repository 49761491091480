import React from 'react'
import { Card } from '../../components'
import { PaymentSummary } from '../payment/PaymentSummary'
import { VillageClientPaymentInterface } from './villageTypes'

export const VillageClientPayment: React.FC<VillageClientPaymentInterface> = (
    props
) => {
    return (
        <div className="height-100">
            <Card shadow className="height-100 village-client-payment-card">
                <PaymentSummary
                    paymentSummary={{ ...props }}
                    paymentHistoryLink
                />
            </Card>
        </div>
    )
}
